<template>
    <div>
<!--      <div v-if="!loggedin && !joinUs">-->
<!--        <span v-if="!chef" @click="showSignup=true;showSignupChef=false" class="ml-2 mr-2">Signup</span>-->
<!--        <span v-else @click="iamachef=true;openSignup=true;showSignupChef=false" class="ml-2 mr-2">Sign Up as a Chef</span>-->
<!--      </div>-->
<!--      <v-btn v-if="joinUs" @click="openSignup=true;showSignupChef=false"-->
<!--             color="#ffffff50"-->
<!--             dark-->
<!--      >-->
<!--        <span >Become a Chef</span>-->
<!--      </v-btn>-->
        <v-dialog v-if="show" v-model="show" persistent max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="headline">Signup {{iamachef()?"as a Chef":""}}</span>
                  <div v-if="iamachef()" class="margin-left-right-default">(Check out <a @click.stop href="/signup" target="_blank">our key features</a>)</div>

                </v-card-title>
                <v-form
                        ref="form"
                        v-model="valid"
                        @submit.prevent="signup()"
                >
                    <v-card-text>
                        <v-container>

                            <v-row>
                                <v-col v-if="error" cols="12">
                                    <div class="error--text">{{error}}</div>
                                </v-col>

                                <v-col cols="12">
                                    <v-text-field id="firstname" dense v-model="user.userDetails.firstname" label="Firstname" :rules="firstnameRule" required ></v-text-field>
                                </v-col>

                                <v-col cols="12">
                                    <v-text-field id="lastname" dense v-model="user.userDetails.lastname" label="Lastname" :rules="lastnameRule" required></v-text-field>
                                </v-col>

                                <v-col cols="12" >
                                    <v-text-field id="phone" dense v-model="user.userDetails.phone" label="Mobile" :rules="mobileRule" required></v-text-field>
                                </v-col>

                                <v-col cols="12">
                                    <v-text-field id="email" v-model="user.email" label="Email" required
                                                  :rules="emailRules"
                                                  dense
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field id="password" v-model="user.password" :rules="passwordRules" label="Password"
                                                  type="password"
                                                  required
                                                  dense
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field id="passwordRepeat" v-model="passwordRepeat" :rules="passwordMatchRules"
                                                  label="Repeat Password" type="password"
                                                  required
                                                  dense
                                    ></v-text-field>
                                </v-col>
                              <v-col cols="12">
                                <v-checkbox
                                        v-model="terms"
                                        :rules="[v => !!v || 'You must agree to continue!']"
                                        required
                                        dense
                                >
                                  <template v-slot:label>
                                  <div>
                                    Do you accept the <a @click.stop href="/terms" target="_blank">Terms and Conditions</a> and our <a @click.stop href="/privacy" target="_blank">Privacy Policy</a>?
                                  </div>
                                  </template>
                                </v-checkbox>
                              </v-col>
                              <v-col>
                                <div>
                                  Already have an account? <a id="login" @click="close();$root.showLogin=true">Login</a>
                                </div>
                              </v-col>


                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn id="btn_iamachef" v-if="!iamachef()" color="primary" text @click="chefLocal=true">Signup as a Chef</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn id="btn_close" color="primary" text @click="close()">Close</v-btn>
                        <v-btn id="btn_signup" color="primary" :disabled="!valid" type="submit" :loading="loading">Signup</v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import {mapMutations} from "vuex";
    import Constants from '../constants';

    export default {
        name: "Signup",
        props: {
            show: {
              type: Boolean,
              default : false,
            },
            chef: {
                type: Boolean,
                default : false,
            },
            joinUs:{
                type: Boolean,
                default : false,
            },
            closeAction: Function,
        },

        data() {
            return {
                chefLocal:false,
                loading:false,
                error: undefined,
                valid: true,
                user: {
                    email:"",
                    password: "",

                    userDetails:{
                        firstname:'',
                        lastname:"",
                        phone:""
                    }
                },
                passwordRepeat: "",
                terms: false,
                passwordRules: [
                    v => !!v || 'Password is required',
                    v => v !== undefined && v.length >= 4 || 'Password must be at least 4 characters',
                ],
                emailRules: [
                    v => !!v || 'E-mail is required',
                    v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                ],
                firstnameRule: [
                    v => !!v || 'Firstname is required',
                ],
                lastnameRule: [
                    v => !!v || 'Lastname is required',
                ],
                mobileRule: [
                    v => !!v || 'Mobile is required',
                    v => v !== undefined && v.length >= 10 || 'Mobile must be at least 10 characters',
                ],
            };
        },
      mounted() {
          this.chefLocal=false;
      },
      computed: {

            passwordMatchRules() {
                return [
                    v => !!v || 'Repeat password is required',
                    () => this.user.password.trim() === this.passwordRepeat.trim() || 'The passwords must match',
                ];
            },
            loggedin() {
                return this.$store.state.loggedin;
            },
        },
        methods: {
            ...mapMutations(["showSnackbar", "closeSnackbar"]),
            validate() {
                //this.$refs.form.validate()
            },
            iamachef(){
              return this.chef || this.chefLocal
            },
            signup() {
                let _this = this;
                this.error = undefined;
                this.loading=true;
                this.$store.dispatch(
                    'signup',
                    {
                       user: this.user, iamachef:this.iamachef()
                    }
                ).then(function (response) {
                    if (response.data.statusCode === 200){
                        if (response.data.infoMessages !==undefined && response.data.infoMessages.CONFIRM_EMAIL !==undefined){
                            _this.showSnackbar({
                                text: Constants.CHECK_CONFIRMATION_EMAIL,
                                color: "primary"
                            });
                        }else if (response.data.infoMessages !==undefined && response.data.infoMessages.ACCOUNT_CHEF !==undefined){
                            _this.showSnackbar({
                                text: response.data.infoMessages.ACCOUNT_CHEF,
                                color: "primary"
                            });
                        }

                      let event={
                        'event_category': "click",
                        'event_label': 'Signup' +_this.iamachef()?" chef":""
                      }
                      if (localStorage && localStorage.getItem("campaign")!==undefined){
                        event.campaign=localStorage.getItem("campaign");
                      }
                      _this.$gtag.event('Signup' +_this.iamachef()?" chef":"",event );
                      // open login
                      _this.close();
                      _this.$root.showLogin=true;
                    }
                }).catch(function (error) {
                        if (error.validationMessages !== null) {
                            let messages = "";
                            Object.entries(error.validationMessages).map(item => {
                                if (messages.length > 0) {
                                    messages += ", ";
                                }
                                messages += item[1];
                            });
                            _this.showSnackbar({
                                text: messages,
                                color: "error"
                            });
                            _this.error = messages;
                        } else {
                            _this.error = error.errorMessage;
                            _this.showSnackbar({text: error.errorMessage, color: "error"});
                        }
                    }
                ).finally(()=>{this.loading=false;});
            },
          close(){
              this.chefLocal=false;
              this.closeAction();
          }
        }
    }
</script>

<style scoped>

</style>